import { Ctrl } from ":mods";
import { ROUTES } from "../const";
import { ShowTutorStage, ShowSubmodule } from "../views";
import { Route } from "@solidjs/router";
import { ShowSubmissionsTable, ShowSubmissionDetails } from "../views";

export function TutorRoutes(props: Ctrl.Routes.model.TutorViewProps) {
  return (
    <>
      <Route path={ROUTES.side.courses.foundation()} element={<ShowTutorStage {...props} />} />
      <Route
        path={[ROUTES.side.courses.foundation_submodule(), ROUTES.side.courses.advanced_submodule()]}
        // @ts-ignore
        element={<ShowSubmodule {...props} />}
      />
      <Route path={[ROUTES.side.courses.foundation_submissions_list()]} element={<ShowSubmissionsTable {...props} />} />
      <Route
        path={ROUTES.side.courses.foundation_submission_student()}
        element={<ShowSubmissionDetails {...props} />}
      />
    </>
  );
}
